import store from '..'

export default {
  getAuthUserLogged: (state) => {
    if (state.user) {
      return state.user
    }

    if (localStorage.getItem('vitale-user')) {
      store.commit('Auth/authSetUser', JSON.parse(localStorage.getItem('vitale-user')))
    }
    return state.user
  },

  getAuthUserLoggedPermissions: (state) => {
    if (state.permissions) {
      return state.permissions
    }

    if (localStorage.getItem('vitale-permissions')) {
      store.commit('Auth/authSetPermissions', JSON.parse(localStorage.getItem('vitale-permissions')))
    }

    return state.permissions
  }
}
